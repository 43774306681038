<template>
    <CModal
      :show.sync="boo_modal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      :size="size"
      color="dark"
    >
        <div class="row">
            <div class="col-md-12 float-center" style="text-align: center;">
                <h4>¿Desea Borrar el registro <strong>{{ item.Nombre != null ? item.Nombre : item.Id }}</strong> ? </h4>
            </div>
        </div>
      <template #header>
        <h6 class="modal-title">{{title}}</h6>
        <CButtonClose @click="mtd_close" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="mtd_close" color="danger">Cancelar</CButton>
        <CButton @click="mtd_commit" color="success">Aceptar</CButton>
      </template>
    </CModal>
</template>

<script>
    export default {
        name:"c-modal-delete",
        props:{
            title: String, 
            boo_modal:Boolean,
            item:{},
            size:String,
        },
        methods: {
            mtd_close(){
                this.$emit('close_delete');
            },
            mtd_commit(){
                this.$emit('mtd_commit');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>